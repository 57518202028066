<template>
  <div
    ref="parent"
    class="parallaxier-parent absolute h-full w-full z-0 overflow-hidden pointer-events-none"
  >
    <div class="parallaxier h-full" ref="parallaxier">
      <slot></slot>
    </div>
  </div>
</template>

<script>

const defaultHandler = (f) => setTimeout(f, 1000 / 60);
const animateFrame = window.requestAnimationFrame
  || window.mozRequestAnimationFrame
  || window.webkitRequestAnimationFrame
  || window.msRequestAnimationFrame
  || defaultHandler;

const maxOffset = 10;
const scale = 1.25;

export default {
  props: {
    identifier: {
      default: '[unknown parallaxer]',
      type: String,
    },
    parallax: {
      default: true,
      type: Boolean,
    },
    strength: {
      default: 1, // value of 0-1 - gets applied to the offset
      type: Number,
    },
    breakpoint: {
      default: '(min-width: 968px)',
      type: String,
    },
    direction: {
      type: String,
      default: 'up',
    },
  },

  data() {
    return {
      mediaQuery: null,
    };
  },

  mounted() {
    this.animateElement();
    this.setupListener();
  },

  methods: {
    animateElement() {
      const { top, height } = this.$refs.parent.getBoundingClientRect();
      const calculation = top / height;

      const boundCalculation = Math.max(Math.min(calculation, 1), -1);

      if (boundCalculation >= 1 || boundCalculation <= -1) {
        return;
      }
      const percentage = boundCalculation * maxOffset * this.strength * this.directionValue;

      const transform = `scale(${scale}) translateY(${percentage}%)`;

      this.$refs.parallaxier.style.transform = transform;
    },

    scrollHandler() {
      animateFrame(() => {
        this.animateElement();
      });
    },

    setupListener() {
      if (!this.mediaQuery) {
        window.addEventListener('scroll', this.scrollHandler, false);
        return;
      }

      if (this.mediaQuery.matches) {
        window.addEventListener('scroll', this.scrollHandler, false);
      } else {
        window.removeEventListener('scroll', this.scrollHandler, false);
      }
    },
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler, false);
  },

  computed: {
    directionValue() {
      return this.direction === 'down' ? +1 : -1;
    },
  },
};
</script>

<style lang="scss">

@layer base {
  .parallaxier-parent {
    scroll-behavior: smooth;
  }

  .parallaxier {
    will-change: transform;
  }

  .parallaxier img {
    @apply h-full w-full object-cover;
  }
}

</style>
