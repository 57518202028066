<template>
  <article
    hero
    class="text-center border border-yellow-100 rounded-lg
    text-yellow-100
    w-72 xs:w-112 lg:w-144
    py-2 xs:py-6
    px-8 sm:px-12"
  >
    <h2 class="text-lg xs:text-2xl lg:text-4xl">
      We're Getting Married
    </h2>

    <h1
      class="font-thin font-cursive text-white
      text-4xl xs:text-6xl lg:text-8xl
      py-4 xs:py-8 lg:py-10"
    >
      Stef & Thea
    </h1>

    <h2 class="text-lg xs:text-2xl text-left">
      <span>May 1st, 2021</span>
      <span class="float-right">{{countdown}}</span>
    </h2>
  </article>
</template>

<script>
import moment from 'moment';
import weddingDate from '../wedding-date';

export default {
  name: 'Hero',
  computed: {
    countdown() {
      const days = weddingDate
        .diff(moment().startOf('day'), 'days');

      if (days === 0) {
        return 'Today';
      }

      if (days === 1) {
        return 'Tomorrow';
      }

      if (days === -1) {
        return 'Yesterday';
      }

      if (days < 0) {
        return `${-days} days ago`;
      }

      return `${days} days`;
    },
  },
};
</script>

<style lang="scss">

@layer base {
  article[hero] {
    box-shadow: 0px 0px 15px rgba(0,0,0,0.4);
    background-color: rgba(0,0,0,0.3);
  }
}

</style>
