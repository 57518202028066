<template>
  <div itinerary class="w-full text-red-50">
    <h1 class="text-3xl border-b border-red-300 text-gray-100 mb-6">
      14:00 Lunch
    </h1>

    <article>
      <p>
        Join us for lunch afterwards, in the Secret Garden at the Northey Arms
        just outside Bath.
      </p>

      <a satnav href="https://goo.gl/maps/zLRjBQk3wmkR2zXK8" target="_BLANK">
        <p>The Northey Arms</p>
        <p class="text-xs">Bath Rd, Corsham, Wiltshire, SN13 8AE</p>
      </a>
    </article>
  </div>
</template>

<script>
export default {
  name: 'Lunch',
};
</script>

<style lang="scss">
@layer base {
  [itinerary] {
    @media (min-width: 640px) {
      width: 36rem;
    }
    @media (min-width: 768px) {
      width: 42rem;
    }
  }
}
</style>
