<template>
  <div window>
    <Section identifier="hero" direction="up">
      <Hero />
    </Section>

    <Section identifier="wedding" direction="down">
      <Wedding />

      <template #parallax>
        <picture>
          <source media="(max-width: 767px)" srcset="/grassy-rings-portrait.jpeg">
          <img src="/grassy-rings-landscape.jpeg" alt="We got rings">
        </picture>
      </template>
    </Section>

    <Section identifier="cyberWedding" direction="down">
      <CyberWedding />

      <template #parallax>
        <picture>
          <source media="(max-width: 767px)" srcset="/indoor-rings-mobile.jpeg">
          <img src="/indoor-rings.jpeg" alt="We went for a walk">
        </picture>
      </template>
    </Section>

    <Section identifier="intermission" direction="down">
      <Intermission />

      <template #parallax>
        <picture>
          <source media="(max-width: 767px)" srcset="/autumn-walk-mobile.jpeg">
          <img src="/autumn-walk.jpeg" alt="We went for a walk">
        </picture>
      </template>
    </Section>

    <Section identifier="lunch" direction="down">
      <Lunch />

      <template #parallax>
        <picture>
          <source media="(max-width: 767px)" srcset="/forest-dog-mobile.jpeg">
          <img src="/forest-dog.jpeg" alt="We went for a walk">
        </picture>
      </template>
    </Section>
  </div>
</template>

<script>
import CyberWedding from './sections/CyberWedding.vue';
import Hero from './sections/Hero.vue';
import Intermission from './sections/Intermission.vue';
import Lunch from './sections/Lunch.vue';
import Section from './components/Section.vue';
import Wedding from './sections/Wedding.vue';

export default {
  name: 'App',
  components: {
    CyberWedding,
    Hero,
    Intermission,
    Lunch,
    Section,
    Wedding,
  },
};
</script>

<style lang="scss">
@import "~@fontsource/alex-brush/latin.css";
@import "~@fontsource/quicksand/latin.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply bg-black;
    @apply text-white;
    @apply font-sans;
  }

  p {
    @apply my-2;
  }

  [satnav] {
    --tw-bg-opacity: 0.6 !important;
    @apply block mx-4 sm:mx-16 my-6 rounded-lg p-3;
    @apply bg-gray-700;
    @apply hover:bg-gray-500;

    p {
      @apply my-0;
    }
  }
}
</style>
