<template>
  <div class="w-full text-green-50">
    <article class="mb-16">
      <h1 class="text-3xl border-b border-white border-green-300 text-gray-100 mb-6">
        12:00 The Wedding (Online)
      </h1>

      <p>
        The wedding ceremony will also be available on Zoom for
        anyone wishing to attend remotely from about 12 noon (BST).
      </p>

      <div v-if="linkIsLive">
        <a satnav href="https://zoom.us/j/99799777450?pwd=YnFKRVdNWmZERXlZb09pRjl0NHQzdz09" target="_BLANK">
          <p>Click here to join the Zoom call</p>
        </a>
      </div>
      <div v-else>
        <p>The link will be available here a few days prior to the wedding itself.</p>
      </div>
    </article>
  </div>
</template>

<script>
import moment from 'moment';
import weddingDate from '../wedding-date';

export default {
  name: 'CyberWedding',
  computed: {
    linkIsLive() {
      console.info(moment());
      console.info(moment(weddingDate).subtract(3, 'days'));
      return moment()
        .isSameOrAfter(
          moment(weddingDate).subtract(3, 'days'),
        );
    },
  },
};
</script>

<style lang="scss">
</style>
