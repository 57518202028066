<template>
  <div class="w-full text-green-50">
    <article class="mb-16">
      <h1 class="text-3xl border-b border-white border-blue-300 text-gray-100 mb-6">
        13:00 Intermission
      </h1>

      <p>
        En-route to the lunch, there will be a short weather-dependent break for people
        to stretch their legs and for any children and/or dogs to run around in a field.
      </p>

      <a satnav href="https://goo.gl/maps/5bvFYCrjjCmAyU477" target="_BLANK">
        <p>Collet's Bottom Wood</p>
        <p class="text-xs">Near Rudloe Manor, Box</p>
      </a>
    </article>
  </div>
</template>

<script>
export default {
  name: 'Intermission',
};
</script>

<style lang="scss">
</style>
