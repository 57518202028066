<template>
  <div class="w-full text-green-50">
    <article class="mb-16">
      <h1 class="text-3xl border-b border-white border-green-300 text-gray-100 mb-6">
        12:00 The Wedding
      </h1>

      <p>
        For COVID reasons, only immediate family can
        attend the wedding in person in Chippenham.
      </p>

      <p>
        For anyone coming in person, please arrive at 12 noon at the latest, for a 12:15 start.
      </p>

      <a satnav href="https://goo.gl/maps/tBXntwMxrtm" target="_BLANK">
        <p>The Wessex Room, Chippenham Registry Office</p>
        <p class="text-xs">4 Timber Street, Chippenham, Wiltshire, SN15 3BZ</p>
      </a>

      <p>
        There is limited parking at the venue itself, but there is a Waitrose
        car park (£1.70 for 2 hours) about 3 minutes walk from the venue itself.
      </p>

      <a satnav href="https://goo.gl/maps/DvmG1JuZFTVSYtRV9" target="_BLANK">
        <p>Borough Parade Car Park</p>
        <p class="text-xs">Chippenham</p>
      </a>
    </article>
  </div>
</template>

<script>
export default {
  name: 'Wedding',
};
</script>

<style lang="scss">
</style>
