<template>
  <section class="relative h-screen">
    <parallax
      class="opacity-50 md:opacity-60"
      :parallax="enabled"
      :direction="direction"
      breakpoint="(min-width: 640px)"
      :identifier="identifier"
    >
      <slot name="parallax">
        <picture>
          <source media="(max-width: 767px)" srcset="/engaged-mobile.jpeg">
          <img src="/engaged.jpeg" alt="We got engaged">
        </picture>
      </slot>
    </parallax>

    <section
      itinerary
      class="relative p-12 sm:p-6 m-auto h-screen flex justify-center items-center"
    >
      <slot />
    </section>
  </section>
</template>

<script>
import Parallax from './Parallax.vue';

export default {
  name: 'Section',
  components: { Parallax },
  props: {
    identifier: {
      default: '[unknown section]',
      type: String,
    },
    enabled: {
      default: true,
      type: Boolean,
    },
    direction: {
      default: 'up',
      type: String,
    },
  },
  computed: {
    parallaxSpeed() {
      return 0.25;
    },
  },
};
</script>

<style lang="scss">
</style>
